<template>
  <div class="page-content" v-if="eternal">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Enternal Mission"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Enternal Mission</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Mission Name"
              label-for="h-mission-name"
              label-cols-md="3"
            >
              <ui-component :component-data="eternal.name" class="border" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Mission Description"
              label-for="h-mission-description"
              label-cols-md="3"
            >
              <ui-component
                :component-data="eternal.description"
                class="border"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Category"
              label-for="h-category"
              label-cols-md="3"
            >
              <b-form-select v-model="eternal.category" :options="categories" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Lesson" label-for="h-lesson" label-cols-md="3">
              <b-form-select v-model="eternal.lesson_id" :options="lessons" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-for="(item, index) in eternal.rewards" :key="index">
          <b-col cols="12">
            <b-form-group label="Reward" label-for="h-reward" label-cols-md="3">
              <b-form-select v-model="item.type" :options="select_rewards" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Number" label-for="h-number" label-cols-md="3">
              <b-form-input
                v-model="item.amount"
                id="h-number"
                placeholder="Number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteEternal"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
import pronunLessonService from "@/modules/pronun/service";
import breakingLessonService from "@/modules/breaking_lesson/service"
export default {
  data() {
    return {
      isUpdating: false,
      isDeleting: false,
      eternal: null,
      lessons: [],
      categories: [
        {
          text: "Pronun",
          value: "pronun",
        },
        {
          text: "Breaking",
          value: "breaking"
        }
      ],
      select_rewards: [
        {
          text: "Box",
          value: "BOX",
        },
        {
          text: "Token",
          value: "TOKEN",
        },
      ],
    };
  },
  computed: {
    eternalId() {
      return this.$route.params.eternal_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Eternal Mission List",
          to: { name: "quest-eternal-list" },
        },
      ];
      items.push({ text: "Eternal Mission Detail", active: true });
      return items;
    },
  },
  watch: {
    "eternal.category": function (newValue) {
      if (newValue == 'pronun') {
        this.getLessions();
        this.eternal.lesson_type = "PRONUN"
      }
      if (newValue == 'breaking') {
        this.getBreakingLessons();
        this.eternal.lesson_type = "BREAKING"
      }
    },
  },
  async created() {
    this.getEternal();
  },
  methods: {
    async getLessions() {
      let response = await pronunLessonService.getAll();
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: item.title,
          };
        });
      }
    },
    async getBreakingLessons(){
      let response = await breakingLessonService.getAll();
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: item.title,
          };
        });
      }
    },
    async getEternal() {
      this.eternal = await service.get({ id: this.eternalId });
    },
    async update() {
      this.isUpdating = true;
      if (this.eternal.category && this.eternal.lesson_id) {
        await service.update({
          data: JSON.stringify(this.eternal),
        });
      } else {
        this.$store.dispatch("pushErrorNotify", {
          text: (!this.eternal.category ? "Category" : "Lesson") + " required",
        });
      }
      this.getEternal();
      this.isUpdating = false;
    },
    async deleteEternal() {
      await service.delete({ id: this.eternalId });
      this.$router.push({ name: "quest-eternal-list" });
    },
  },
};
</script>
